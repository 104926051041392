<template>
    <div id="personal_app_div">
        <div style="" class="title_div">
            {{title}}
        </div>
        <div style="margin-top:47px;">
            <div v-show="active==0">
                <personalOrder></personalOrder>
            </div>
            <div v-show="active==1">
                <uploadorder></uploadorder>
            </div>  
            <div v-show="active==2">
                <personalInfo></personalInfo>
            </div>
        </div>
        <div class="tabbar_bottom_div">
            <van-tabbar v-model="active"  @change="onChange">
                <van-tabbar-item v-for="(item,index) in titleArr"  :key="index" :icon="active==index?item.active_icon:item.icon">{{item.title}}</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>
<script>
import {validToken} from '../../api/login'
import {personalActive} from '../../store/personal'
import personalOrder from '../personalOrder/personalOrder.vue'
import personalInfo from '../personalUserInfo/personalInfo.vue'
import uploadorder from '../upload/uploadOrder.vue'
import { Tabbar,Cascader, Uploader,Field,Popup,Button,RadioGroup,Radio,TabbarItem,CellGroup,Cell } from 'vant';

export default({
    name:'mIndex',
    components: {
        personalOrder,
        uploadorder,
        personalInfo,
        [Button.name]: Button,
        [Popup.name]: Popup,
        [Field.name]: Field,
        [Uploader.name]: Uploader,
        [Cascader.name]: Cascader,
        [Tabbar.name]: Tabbar,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [TabbarItem.name]: TabbarItem ,
        [CellGroup.name]: CellGroup ,
        [Cell.name]: Cell ,
    },
    data() {
        return {
            payMoney:199,
            title:"车辆信息输入",
            user:'',
            city_show:false,
            show:false,
            fileList:[],
            doorPhotoList:[],
            idCardlist:[],
            options:[],
            fieldValue:'',
            cascaderValue:'',
            titleArr:[
                {title:'已提交报废车',active_icon:'wap-home',icon:'wap-home-o'},
                {title:'提交报废车',active_icon:'add',icon:'add-o'},
                {title:'我的信息',active_icon:'manager',icon:'manager-o'}],
            form:{
                province:0,
                city:0,
                country:0,
                businessLicenceImg:'',
                name:'',
                idCard:'',
                name_err:'',
                id:'',
                doorPhoto:'',
                idCardImg:''
            },
            form_err:{
                name_err:'',
                identity_err:'',
            },
            active: 1,
        }
    },
    methods:{        
        onChange(index) {
            console.log(index);
            this.title =  this.titleArr[index].title;
            personalActive.set(index);
        },
    },
    created(){
        this.active = parseInt(personalActive.get()||'0');
        validToken().then(()=>{})
    }
})
</script>
<style scoped>
@import './personalIndex.css';
</style>