import { render, staticRenderFns } from "./personalIndex.vue?vue&type=template&id=12a31496&scoped=true&"
import script from "./personalIndex.vue?vue&type=script&lang=js&"
export * from "./personalIndex.vue?vue&type=script&lang=js&"
import style0 from "./personalIndex.vue?vue&type=style&index=0&id=12a31496&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a31496",
  null
  
)

export default component.exports