<template>
    <div id="personal_app_div">
        <div style="margin:20px 0px;">            
        </div>
        <van-dialog v-model="img3_show" style="text-align:center;" title="身份证照片" :closeOnClickOverlay='true' :showConfirmButton="false" >
            <van-empty image="error" v-if="!user.idCardPhoto" description="未上传" />
            <img :src="$cosImg(user.idCardPhoto)" v-if="user.idCardPhoto" style="width:100%;height:auto;" />
            <van-uploader :after-read="afterRead_img1" >
                <van-button style="width:200px;margin:10px 0px;"  round block  type="primary">修改</van-button>
            </van-uploader> 
        </van-dialog>
        <div style="padding:0px 10px;">
            <div class="user_info">
                <van-cell-group style="padding:10px 0px;">
                    <div class="cell_left">姓名：</div>
                    <div class="cell_right" style="width:48%;">
                        <van-popover
                        v-model="showPopover"
                        placement="top"
                        trigger="click">
                        <div style="padding:10px;">
                            点击可进行修改
                        </div>
                        <template #reference>
                            <van-field v-model="user.realName" @input="checkName" @blur='updateInfo' input-align="right" />
                        </template>
                        </van-popover>
                    </div>
                    <div v-show="name_show" style="text-align:right;color:red;padding-right:20px;font-size:14px;">
                        <font>姓名错误</font>
                    </div>
                </van-cell-group>
                <van-cell-group style="padding:10px 0px;" >
                    <div class="cell_left" style="width:40%">身份证号码：</div>
                    <div class="cell_right" style="width:58%;">
                        <van-popover
                        v-model="showPopover"
                        placement="top"
                        trigger="click">
                        <div style="padding:10px;">
                            点击可进行修改
                        </div>
                        <template #reference>
                            <van-field maxlength="18" v-model="user.idCard" @blur='updateInfo' @input="checkIdCard" input-align="right" />
                        </template>
                        </van-popover>
                    </div>
                    <div v-show="idcard_show" style="text-align:right;color:red;padding-right:20px;font-size:14px;">
                        <font>身份证号码格式错误</font>
                    </div>
                </van-cell-group>                
            </div>                       
        </div>
        <van-cell-group style="padding:10px 0px;">
                <!-- <van-cell @click="$router.push({path:'/userBill',query:{go_url:'/personalIndex'}})" is-link >
                    <template #title>
                        <van-icon name="after-sale" color="#efc086" size="20" style="vertical-align: -21%" />
                        &nbsp;
                        <span class="custom-title">我的账单</span>
                    </template>
                </van-cell> -->
                <van-cell @click="goWallet" is-link >
                    <template #title>
                        <van-icon name="cash-on-deliver" color="#efc086" size="20" style="vertical-align: -21%" />
                        &nbsp;
                        <span class="custom-title">我的钱包</span>
                    </template>
                </van-cell>
                <div> <van-button  block  @click="img3_show = true" >身份证照片</van-button></div>
        </van-cell-group>
        <van-button type="default" @click="user_layout" plain   block>安全退出</van-button>
            <div style="height:100px"></div>
            <div class="btn_div">
        </div>
        <div style="height:100px"></div>
        <div class="btn_div">
        </div>
    </div>
</template>
<script>
import {personalUser} from '../../store/personal'
import {ScrapUpdateInfo,saveScrapIDCardPhoto} from '../../api/personal'
import {userLogout} from '../../api/login'
import {logout} from '../../store/token'
import { Dialog,Uploader,Button,Cell,CellGroup,Field,Form,Tag,Popover,Icon,Empty } from 'vant';
export default({
    name:'detail',
    components: {
        [Button.name]: Button,
        [Field.name]: Field,
        [Uploader.name]: Uploader,
        [Dialog.name]: Dialog,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Form.name]: Form,
        [Tag.name]: Tag,
        [Popover.name]: Popover,
        [Icon.name]: Icon,
        [Empty.name]: Empty,
        [Dialog.Component.name]:Dialog.Component
    },
    data() {
        return {
            idcard_show:false,
            name_show:false,
            readonlyReadonly:true,
            cummission_show:false,
            providerId:'',
            showPopover:false,
            img1_show:false,
            img2_show:false,
            img3_show:false,
            id:this.$route.query.id,        
            user:'',  
            formData:{
                id: 0,
                maxCommission: '',
                minCommission: '',
                percentCommission: ''
            }
        }
    },
    methods:{
        user_layout(){
            userLogout().then(res=>{
                this.$toast({type:res.status?'success':'fail',message:res.message});
                if(res.status){
                    logout();
                }
            })
        },
        goWallet(){
            if(!this.user.idCard || !this.user.realName){
                this.$toast.fail("请完善您的身份信息！");
            }else{
                this.$router.push('/bank/wallet')
            }
        },
        checkName(){
            if(this.user.realName.length==0||this.user.realName.length>10){
                this.name_show = true;
            }else{
                this.name_show = false;
            }
        },
        checkIdCard(){
            var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            if(!p.test(this.user.idCard)){
                this.idcard_show=true;
            }else{
                this.idcard_show = false;
            }
        },
        updateInfo(){
            if(this.idcard_show||this.name_show){
                return;
            }
            var body = {
                IdCard:this.user.idCard,
                RealName:this.user.realName,
            }
            ScrapUpdateInfo(body).then(res=>{
                if(res.status){
                    personalUser.set(this.user)
                }
            });
        },
        afterRead_img1(file){
            this.uploadFile(file);
        },
        uploadFile(file){
            var filename = file.file.name;
            var ext = filename.substr(filename.lastIndexOf('.'));
            this.$util.uploadFile(ext,file,(err,data)=>{
                var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                var fileurl = this.$global.BaseImagePath + filename;
                this.user.idCardPhoto = fileurl;
                saveScrapIDCardPhoto({photo:fileurl}).then(()=>{
                    personalUser.set(this.user)
                    this.$toast('替换成功');
                });
            });
        }   
    },
    created(){
        this.user = personalUser.get();
    }
})
</script>
<style scoped>
.cell_left{
    display:inline-block;width:50%;
}
.cell_right{
    display:inline-block;width:50%;text-align:right;
}
.form_img{
    width: 80px;
    height: 80px;
}
</style>