<template>
    <div id="app">
        <div class="top_btn_div">
            <van-search
                v-model="likeLicense"
                shape="round"
                show-action
                background="#4fc08d"
                @search="search"
                placeholder="请输入车牌号" >
                 <template #action>
                    <div @click="search">搜索</div>
                </template>
            </van-search>
        </div>
        <div class="cards_div" >
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-card v-for="(item,index) in tableData" :key="index"
                :title="item.licensePlate"
                :thumb="$cosImg(item.frontLicensePhoto)"
                :thumb-link="'/morderdetail?id='+item.orderId+'&go_url=/personalIndex&go_active=0'">
                <template #tags>
                    <van-tag plain :color="tagStyle(item.orderStatus)" type="danger">{{$enum.myOrderStatus[item.orderStatus]}}</van-tag>
                </template>
                <template #desc>
                    <div>
                        {{item.model}}
                    </div>
                    <div>
                        {{item.provinceName+"  "+item.cityName + "  "+item.countryName}}
                    </div>
                    <div>
                        <label>系统估价：</label>￥{{Math.floor(item.customerPrice)}}
                    </div>
                    <div v-if="item.scrapPrice!=0">
                        <label>成交价：</label>￥{{item.scrapPrice}}
                    </div>
                </template>
                <template #footer>
                    <van-button @click="photos =true;form.id=item.id;" size="small"  v-if="(!item.frontLicensePhoto)||(!item.backLicensePhoto)" >补充行驶证</van-button>
                    <van-button @click="$router.push({path:'/morderdetail',query:{id:item.orderId,go_url:'/personalIndex',go_active:'0'}})" size="small" >详情</van-button>
                </template>
            </van-card>
                <div style="margin-bottom:80px;line-height:40px;text-align:center;color:#c1c1c1">
                    没有更多了
                </div>
            </van-pull-refresh>
             <van-dialog v-model="photos" title="补充行驶证" :closeOnClickOverlay='true' :showConfirmButton="false">
                <div style="text-align:center;">
                    <van-uploader :after-read="uploadFrontCerts"  :max-count="1">
                            <van-button block  >上传主页</van-button>
                    </van-uploader>  
                    <van-uploader :after-read="uploadBackCerts"  :max-count="1">
                            <van-button block >上传副页</van-button>
                    </van-uploader>  
                </div>
                <div style="padding:10px;">
                    <van-button style="width:48%;" @click="photos=false" >取消</van-button>
                    <van-button style="width:48%;" @click="uploadCerts" type="primary">确定</van-button>
                </div>
            </van-dialog>
        </div>
    </div>
</template>
<script>
import {SaveLicensePhoto} from '../../api/provider'
import {getSelfList} from '../../api/personal'
import { CellGroup,Field,ActionSheet,DatetimePicker,PullRefresh,Card,Tag,Button,Search,Uploader, Dialog } from 'vant';
import {personalUser} from '../../store/personal'
    export default({
        name:'pricing',
        components:{
            [CellGroup.name]: CellGroup,
            [Field.name]: Field,
            [Button.name]: Button,
            [ActionSheet.name]: ActionSheet,
            [DatetimePicker.name]: DatetimePicker,
            [PullRefresh.name]: PullRefresh,
            [Card.name]: Card,
            [Tag.name]: Tag,
            [Search.name]: Search,
            [Uploader.name]: Uploader,
            [Dialog.Component.name]:Dialog.Component
        },
        data() {
            return {
                isInit:false,
                isLoading:false,
                date_show:false,
                action_show:false,
                showSearchDialog:false,
                columns:[],
                pageindex:1,
                tableData:[],
                user:'',
                likeLicense:'',
                totalPages:'',
                photos:false,
                form:{
                    id:'',
                    frontLicensePhoto:'',
                    backLicensePhoto:''
                }
            }
        },
        methods:{
            search(){
                this.onRefresh();
            },
            tagStyle(status){
                switch(status){
                    case 60:
                    case 80:
                        return '#c1c1c1';
                    case 0:
                    case 10:
                    case 20:
                    case 30:
                    case 40:
                    case 50:
                    case 70:
                        return '#1989fa';
                    default:
                        return '#07c160';
                }
            },
            initTableData(){
                if(this.isInit){
                    return;
                }else{
                    this.isInit = true;
                }
                if(this.pageindex==this.totalPages){
                    return;
                }               
                var body = {
                    IsDesc:true,
                    PageSize:20,
                    PageIndex:this.pageindex,
                    LikeLicense:this.likeLicense,
                    SearchContent:this.user.phone,
                    ScrapUserId:this.user.id
                }
                this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
                getSelfList(body).then(res=>{
                    this.$toast.clear();
                    //this.tableData = [];
                    var data = res&&(res.content||[]);
                    data.forEach(e => {
                        this.tableData.push(e);
                    });
                    this.totalPages = res.totalPages;
                    if(data.length>0){
                        this.pageindex++;
                    }
                    this.isInit = false;
                    this.isLoading = false;
                });
            },
              uploadFrontCerts(file){
                var filename = file.file.name;
                    var ext = filename.substr(filename.lastIndexOf('.'));
                    if(!this.$util.validUplodImgExt(ext)){
                        console.log(ext);
                        this.$toast("不支持的图片格式");
                        return;
                    }
                    this.$toast.loading({duration: 0,forbidClick: true,message: '上传中'});
                    this.$util.uploadFile(ext,file,(err,data)=>{
                        this.$toast.clear();
                        var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                        this.form.frontLicensePhoto = this.$global.BaseImagePath + filename;
                        this.$toast.success('上传主页成功');
                    });
            },
            uploadBackCerts(file){
                var filename = file.file.name;
                    var ext = filename.substr(filename.lastIndexOf('.'));
                    if(!this.$util.validUplodImgExt(ext)){
                        console.log(ext);
                        this.$toast("不支持的图片格式");
                        return;
                    }
                    this.$toast.loading({duration: 0,forbidClick: true,message: '上传中'});
                    this.$util.uploadFile(ext,file,(err,data)=>{
                        this.$toast.clear();
                        var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                        this.form.backLicensePhoto = this.$global.BaseImagePath + filename;
                        this.$toast.success('上传副页成功');
                    });
            },
            uploadCerts(files,detail){
                console.log(detail);
                SaveLicensePhoto(this.form).then(res=>{
                    if(res.status){
                        this.form = {};
                        this.$toast.success('上传成功');
                        this.onRefresh();
                        this.photos = false;
                    }else{
                        this.$toast.fail(res.message);
                    }
                })
                return;             
            },
            getDocumentTop() {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;    return scrollTop;
            },
            getWindowHeight() {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
            },
            getScrollHeight() {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;    return scrollHeight;
            },
            onCancel(){
            },
            onConfirm(){
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            onSelect(item){
                console.log(item);
                this.serarchModel.orderStatusText = item.name;
                this.serarchModel.orderStatus = item.id;
            },
            onRefresh(){
                this.pageindex = 1;
                this.totalPages = 0;
                this.tableData = [];
                this.isLoading = true;
                this.initTableData(this);
            },
            scrollEvent(){
                if((this.getScrollHeight()-5) <= this.getWindowHeight() + this.getDocumentTop()){                    
                    this.initTableData(this);
                }
            }
        },
        destroyed(){
            console.log('beforeRouteLeave');
             window.removeEventListener('scroll',this.scrollEvent);
        },        
        mounted(){
            this.user = personalUser.get();
            this.initTableData(this);
            window.addEventListener('scroll',this.scrollEvent)
        },
        created(){
        }
    })
</script>
<style scoped>
.top_btn_div{
    position: fixed;top: 0px;right: 0px;
    width: 100%;
    height: 54px;
    z-index: 2;
}
.cards_div{
    margin-top: 54px;
}
</style>